.partners {
    padding-bottom: 160px;
    display: flex;
    justify-content: center;
}

.container {
    /* max-width: 1280px; */
    padding: 0 4rem;
}

.title {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    margin-bottom: 2.5rem;
}

@media (min-width: 1920px) {
    .container {
        max-width: 80%;
    }
  }

@media (min-width: 1440px) and (max-width: 1920px) {
    .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        padding: 0 1rem;
    }

    .partners {
        padding-bottom: 120px;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 2rem;
    }
}
