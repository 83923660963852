.nameBlock {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
}

.partner{
    width: 100%;
}

.partnersGrid {
    width: 100%;
    display: grid;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(6,minmax(0,1fr));
}

@media only screen and (max-width: 1366px)  {
    .partnersGrid {
        grid-template-columns: repeat(4,minmax(0,1fr));
    }
}

@media only screen and (max-width: 1024px)  {
    .partnersGrid {
        grid-template-columns: repeat(3,minmax(0,1fr));
    }
}

@media only screen and (max-width: 768px)  {
    .nameBlock {
        margin-bottom: 1rem;
    }

    .partnersGrid {
        width: 100%;
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}

