.investors {
    margin-bottom: 70px;
}

.nameBlock {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
}

.investor {
    max-width: 100%;
    width: 100%;
}

.investorsGrid {
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-gap: 1rem;
    width: 100%;
}

@media (min-width: 1920px) {
    .investorsGrid {
        grid-template-columns: repeat(6,minmax(0,1fr));
    }
}


@media only screen and (max-width: 1200px)  {
    .investorsGrid {
        grid-template-columns: repeat(3,minmax(0,1fr));
    }
}

@media only screen and (max-width: 768px)  {
    .investors {
        margin-bottom: 1.75rem;
    }

    .nameBlock {
        margin-bottom: 1rem;
    }

    .investorsGrid {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}
