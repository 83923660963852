.teamWork{
    margin-bottom: 70px;
}

.nameBlock {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    color: #ffffff;
    margin-bottom: 1.5rem;
}

.teamWorkGrid {
    grid-template-columns: repeat(4,minmax(0,1fr));
    column-gap: 1rem;
    grid-row-gap: 1.5rem;
    width: 100%;
}

/* .team {
    max-width: 100%;
    width: 100%;
} */
.blockImg{
    margin-bottom: 0.5rem;
}

.nameTeam {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    text-align: left;
    margin-top: 8px;
}

@media (min-width: 1920px) {
    .teamWorkGrid {
        grid-template-columns: repeat(6,minmax(0,1fr));
    }
}

@media only screen and (max-width: 1200px)  {
    .teamWorkGrid {
        grid-template-columns: repeat(3,minmax(0,1fr));
    }
}

@media only screen and (max-width: 768px)  {
    .teamWork{
        margin-bottom: 1.75rem;
    }

    .nameBlock {
        margin-bottom: 1rem;
    }

    .teamWorkGrid {
        grid-template-columns: repeat(2,minmax(0,1fr));
        grid-row-gap: 1rem;
    }

    .blockImg{
        margin-bottom: 0.25rem;
    }
}
